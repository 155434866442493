export default {
  lang1: '提款',
  lang2: '帳號詳細資料',
  lang3: '訂單',
  lang4: '搶單',
  lang5: '會員等級',
  lang6: '所需金額',
  lang7: '每張訂單的佣金',
  lang8: '訂單數量',
  lang9: '加入',
  lang10: '可用餘額',
  lang11: '凍結平衡',
  lang12: '待處理',
  lang13: '已支付',
  lang14: '已結算',
  lang15: '還沒有數據',
  lang16: '訂單總數',
  lang17: '佣金',
  lang18: '提交訂單',
  lang19: '訂單號碼',
  lang20: '產品產量',
  lang21: '當前餘額',
  lang22: '計算公式',
  lang23: '從該訂單中獲利',
  lang24: '提交',
  lang25: '立即搶單',
  lang26: '取得佣金',
  lang27: '今天是任務',
  lang28: '今天完成',
  lang29: '過程',
  lang30: '點選「開始任務」按鈕，依照指示完成任務',
  lang31: '完成任務後，可以將佣金結算到餘額',
  lang32: '單價',
  lang33: '回扣',
  lang34: '訂單正在進行中',
  lang35: '取消佇列',
  lang36: '由於現階段搶單的使用者較多，系統正在努力分配訂單。目前排名第11名。請耐心等待。',
  lang37: '溫馨提示：升級VIP等級可以幫助您更快獲得訂單',
  lang38: '如果您有任何疑問或需要協助，請聯絡線上客服',
  lang39: '點我聯絡您的專屬客服⭣⭣⭣',
  lang40: '常見問題',
  lang41: '如何儲值，有哪些付款方式？',
  lang42: '『這個系統對多個國家開放。我們提供國際支付方式並支持不同國家的當地貨幣。',
  lang43: '我如何投資並賺取收入？',
  lang44: '『可以在系統中搶單，每天獲得穩定的自動下單收入和利息。所有收入都可以提取到您的銀行卡。',
  lang45: '如何註冊成為會員？',
  lang46: '新會員透過邀請註冊。為了確保註冊會員的權益，防止過度註冊減少權益，新會員只能在現有會員的邀請下註冊。',
  lang47: '購買數量有限制嗎？',
  lang48: '是的，為了讓更多會員有機會獲得福利，系統中每個帳戶都有最低購買金額限制。',
  lang49: '操作過程中遇到問題怎麼辦？',
  lang50: '您可以隨時聯絡線上客服，協助完成所有操作。',
  lang51: '聯絡客服',
  lang52: '歡迎',
  lang53: '儲值',
  lang54: '存取款記錄',
  lang55: '我的團隊',
  lang56: '邀請朋友',
  lang57: '取款銀行卡',
  lang58: '安全中心',
  lang59: '設定',
  lang60: '銀行名稱',
  lang61: '幫助',
  lang62: '我的',
  lang63: '訂單歷史記錄',
  lang64: '客戶服務',
  lang65: 'Costco'
};