var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help" },
    [
      _c("nav-header", { attrs: { title: _vm.$t("addLang.lang64") } }),
      _c(
        "div",
        { staticClass: "helpCenter" },
        [
          _c("div", { staticClass: "server-box" }, [
            _c(
              "div",
              { staticClass: "text", attrs: { "data-v-8155f68e": "" } },
              [_vm._v(_vm._s(_vm.$t("addLang.lang38")))]
            ),
            _c("h4", { attrs: { "data-v-8155f68e": "" } }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang39")))
            ]),
            _c("div", { staticClass: "server-img" }, [
              _c("img", {
                attrs: { src: require("@img/home/help1.jpg"), alt: "" }
              })
            ]),
            _c("h3", { attrs: { "data-v-8155f68e": "" } }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang40")))
            ])
          ]),
          _c("ProblemList")
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer_btn" },
        [
          _c(
            "van-button",
            {
              attrs: { type: "primary", size: "large" },
              on: {
                click: function($event) {
                  return _vm.showOnlineService()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("addLang.lang51")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }