export default {
  lang1: 'Withdrawal',
  lang2: 'Account Details',
  lang3: 'Record',
  lang4: 'orders',
  lang5: 'Membership Level',
  lang6: 'Amount Required',
  lang7: 'Commission per order',
  lang8: 'Number of orders',
  lang9: 'Join',
  lang10: 'Available balance',
  lang11: 'Freeze Balance',
  lang12: 'Pending',
  lang13: 'Paid',
  lang14: 'Settled',
  lang15: 'No data yet',
  lang16: 'Order Total',
  lang17: 'commission',
  lang18: 'Submit Order',
  lang19: 'Order Number',
  lang20: 'Product yield',
  lang21: 'Current Balance',
  lang22: 'Calculation formula',
  lang23: 'Profit from this order',
  lang24: 'submit',
  lang25: 'Grab the order now',
  lang26: 'Get commission',
  lang27: 'Today is Tasks',
  lang28: 'Completed today',
  lang29: 'Procedure',
  lang30: 'Click the "Start Task" button and follow the prompts to complete the task.',
  lang31: 'After completing the task, you can settle the commission to the balance',
  lang32: 'unit price',
  lang33: 'Rebate',
  lang34: 'Order in progress',
  lang35: 'Cancel queue',
  lang36: 'Due to the large number of users competing for orders at the current level, the system is working hard to assign orders. It is currently ranked 11th. Please wait patiently.',
  lang37: 'Tips: Upgrading your VIP level can help you get orders faster',
  lang38: 'If you have any questions or need help, please contact online customer service',
  lang39: 'Click me to contact your exclusive customer service⭣⭣⭣',
  lang40: 'FAQ',
  lang41: 'How to top up and what payment methods are there?',
  lang42: 'This system is open to multiple countries. We provide international payment methods and support local currencies for different countries.',
  lang43: 'How can I invest and earn income?',
  lang44: 'You can grab orders in the system and get stable automatic order-placing income and interest every day. All earnings can be withdrawn to your bank card.',
  lang45: 'How to register as a member?',
  lang46: 'New members are registered by invitation. In order to ensure the benefits of registered members and prevent excessive registration from reducing benefits, new members can only register under the invitation of existing members.',
  lang47: 'Is there a limit on the purchase amount?',
  lang48: 'Yes, in order to allow more members to have the opportunity to gain benefits, each account in the system has a minimum purchase amount limit.',
  lang49: 'What should I do if I encounter problems during operation?',
  lang50: 'You can contact online customer service at any time to assist in completing all operations.',
  lang51: 'Contact Customer Service',
  lang52: 'welcome',
  lang53: 'top up',
  lang54: 'Deposit and Withdrawal Records',
  lang55: 'My Team',
  lang56: 'Invite friends',
  lang57: 'Withdrawal bank card',
  lang58: 'Security Center',
  lang59: 'set up',
  lang60: 'Bank Name',
  lang61: 'help',
  lang62: 'my',
  lang63: 'Order History',
  lang64: 'Customer Service',
  lang65: 'Costco'
};