export default {
  lang1: 'ログイン',
  lang2: 'あなたも使うことができます',
  lang3: 'メール',
  lang4: '携帯番号',
  lang5: '携帯番号',
  lang6: '携帯番号を入力してください',
  lang7: 'メール',
  lang8: 'メールアカウントを入力してください',
  lang9: 'ログインパスワード',
  lang9a: 'ログインパスワードを入力してください',
  lang10: 'パスワードを忘れた',
  lang11: 'サインイン',
  lang12: '国/地域を検索',
  lang13: '一般的に使用される領域',
  lang14: 'メール',
  lang15: '携帯番号',
  lang16: 'ログインパスワードの設定',
  lang17: '6〜20文字,文字と数字で構成',
  lang18: 'ログインパスワードの確認',
  lang19: 'パスワードをもう一度入力してください',
  lang20: 'コード',
  lang21: '確認コードを入力してください',
  lang22: '送信',
  lang23: '招待コード（オプション）',
  lang23a: '招待コードを入力してください',
  lang24: '私は読んで同意しました',
  lang25: 'ユーザー契約',
  lang26: 'プライバシーポリシー',
  lang27: '正しいメールアドレスを入力してください',
  lang28: '6〜20桁の数字と文字で構成されるログインパスワードを入力してください',
  lang29: '2つの入力パスワードに一貫性がありません',
  lang30: 'Androidダウンロード',
  lang31: 'iOSダウンロード',
  lang32: 'パスワードを取得した後,24時間以内に通貨を引き出すことはできません',
  lang33: 'パスワードをリセット',
  lang34: 'メール',
  lang35: '携帯電話',
  lang36: 'クリックしてログイン',
  lang37: 'Costcoへようこそ',
  lang38: 'ようこそ',
  lang39: '実名認証',
  lang40: '認証済み',
  lang41: '認証に移動',
  lang42: '認証レビュー',
  lang43: '認証に失敗しました',
  lang44: 'ID情報',
  lang45: '名前',
  lang46: '名前を入力してください',
  lang47: 'IDカード',
  lang48: 'ID番号を入力してください',
  lang49: '証明写真のアップロード（表と裏）',
  lang50: '認証に成功しました',
  lang51: 'ID画像をアップロードしてください',
  lang52: 'アカウントセーフティ',
  lang53: 'ログインパスワード',
  lang54: '資金調達パスワード',
  lang55: 'チェンジ',
  lang56: '設定',
  lang57: 'パスワードを変更した後,24時間以内の引き出しと法定預金は禁止されています',
  lang58: '古いパスワード',
  lang59: '古いパスワードを入力してください',
  lang60: '新しいパスワード',
  lang61: '新しいパスワードを入力してください',
  lang62: 'パスワードの確認',
  lang63: '確認パスワードを入力してください',
  lang64: 'パスワードは6〜20の数字と文字である必要があります',
  lang65: 'パスワードをお忘れですか？',
  lang66: '6〜20桁の数字と文字で構成される古いパスワードを入力してください！',
  lang67: '6〜20桁の数字と文字で構成される新しいパスワードを入力してください！',
  lang68: 'メール',
  lang69: 'SMS',
  lang70: 'ファンドのパスワードを入力してください',
  lang71: 'ファンドのパスワードを確認してください',
  lang72: '6〜20桁の数字と文字で構成されるファンドのパスワードを入力してください！',
  lang73: 'バインディング',
  lang74: '携帯番号を変更',
  lang75: '携帯番号をバインドする',
  lang76: '携帯番号を設定',
  lang77: 'バインドする携帯番号を入力して,番号を確認してください',
  lang78: 'アカウントのセキュリティを保護するには,アカウントを確認する必要があります',
  lang79: 'セキュリティ検証のために検証コードを{tel}に送信してください',
  lang80: 'アカウントのセキュリティ検証',
  lang81: '次へ',
  lang82: 'メールの変更',
  lang83: 'メールをバインド',
  lang84: 'アカウントバインディングメールを設定',
  lang85: 'バインドするメールアドレスを入力し,メールアドレスを確認してください',
  lang86: '私のチーム',
  lang87: 'チームメンバーの合計',
  lang88: '招待者の数',
  lang89: 'チームリスト',
  lang90: 'ユーザーID',
  lang91: '招待時間',
  lang92: '時間',
  lang93: '収益',
  lang94: '収益の詳細',
  lang95: '友達を招待',
  lang96: 'Costcoに友達を推薦してください',
  lang97: '取引手数料の最大10％のリベート',
  lang98: '友達を招待して一緒に登録してください',
  lang99: '招待状を送信',
  lang100: '友達のために',
  lang101: '友達登録',
  lang102: '取引を行う',
  lang103: '対応する',
  lang104: '比例的リベート',
  lang105: '私のコード',
  lang106: 'マイリンク',
  lang107: 'ポスターを表示',
  lang108: '報酬ルール',
  lang109: '1.ユーザーはプロモーションコードまたはプロモーションリンクを共有して登録を完了します。友人が実際の通貨取引,ETF取引,契約取引を生成するたびに,共有者は手数料の報酬の対応する割合を受け取ることができます。12月の場合将来的には,共有者を通じて口座を開設した友人を招待した友人も,10％の手数料報酬を単独で享受できるようになります。',
  lang110: '2.報酬比率:通貨,ETF,契約取引報酬比率:報酬比率は,共有者の1日/平均CTTトークン保有量に関連しています。具体的なルールは次のとおりです:',
  lang111: '（1）CTTトークンが500未満,報酬率が10％',
  lang112: '（2）500-5000 CTTトークンを保持し,報酬率は20％です',
  lang113: '（3）5,000 CTTを超えるトークンを保持している場合,報酬率は30％です',
  lang114: '（4）Costcoグローバルパートナー,より高い報酬についてはプラットフォームスタッフにお問い合わせください。',
  lang115: '3.報酬フォーム:共有者が実際に取得した報酬は,友人が取引手数料を決済するために使用した実際の通貨で返されます。たとえば,友人がETHを使用して取引手数料を決済した場合,実際に取得した報酬は共有者によるものはETHで返還されます。友人の取引手数料がCTT控除によって決済されると,共有者と友人によって得られた実際の報酬はCTTで返還されます。 ',
  lang116: '画像を保存',
  lang117: '画像を正常に保存してください',
  lang118: '画像の保存に失敗しました',
  lang119: 'ヘルプセンター',
  lang120: 'Costco製品チーム',
  lang121: '私たちとのコミュニケーションを歓迎します。あなたのフィードバックのすべての部分が評価されます',
  lang122: '問題の詳細',
  lang123: '作業指示書の送信',
  lang124: '作業指示書のタイトル',
  lang125: 'チケットのタイトルを入力してください',
  lang126: '作業指示書の内容',
  lang127: '問題を詳しく説明してください。より効果的に支援します！',
  lang128: '作業指示リスト',
  lang129: '返信:',
  lang130: '処理中です。しばらくお待ちください...',
  lang131: 'まだ作業指示はありません',
  lang132: '待機中',
  lang133: '応答済み',
  lang134: 'クローズ',
  lang135: '画像サイズは5MBを超えることはできません',
  lang136: '作業指示書の内容を入力してください',
  lang137: '作業指示書の単語数は10〜200です',
  lang138: '私たちについて',
  lang139: 'Costco取引プラットフォーム',
  lang140: 'カスタマーサービスの電話',
  lang141: '公式Twitter',
  lang142: '公式電報',
  lang143: '会社のメールボックス',
  lang144: '設定',
  lang145: '言語設定',
  lang146: 'バージョン',
  lang147: 'ログアウト',
  lang148: 'ログアウトしてもよろしいですか？',
  lang149: '世界をリードする',
  lang150: 'デジタル資産取引プラットフォーム',
  lang151: 'Costcoのダウンロードへようこそ',
  lang152: 'アカウントの登録',
  lang153: '多機能で民主的な暗号交換。市場で最初に,アクティブユーザーに経済的利益をもたらします。Costcoは全体的なプロジェクトです。つまり,暗号通貨ソリューションに関するいくつかのビジネスレイヤーにソリューションを実装して,ユーザーが使いやすくすることを意味します。',
  lang154: '私たちのビジョン',
  lang155: '近い将来,ブロックチェーンテクノロジーの幅広い適用と暗号通貨のメリットを確信しています。技術革新だけでなく教育のおかげでグローバルソリューションを導入することで,暗号世界のエコシステムはすべての人にとってよりフレンドリーになります。',
  lang156: 'アプリをダウンロード',
  lang157: '白い紙',
  lang158: 'クレジット'
};