export default {
  lang1: 'Rút tiền',
  lang2: 'Chi tiết tài khoản',
  lang3: 'Ghi chép',
  lang4: 'Đơn hàng',
  lang5: 'Cấp độ thành viên',
  lang6: 'Số tiền yêu cầu',
  lang7: 'Hoa hồng mỗi đơn hàng',
  lang8: 'Số lượng đơn hàng',
  lang9: 'Tham gia',
  lang10: 'Số dư khả dụng',
  lang11: 'Số dư đóng băng',
  lang12: 'Đang chờ xử lý',
  lang13: 'Đã thanh toán',
  lang14: 'Đã thanh toán',
  lang15: 'Chưa có dữ liệu',
  lang16: 'Tổng đơn hàng',
  lang17: 'Hoa hồng',
  lang18: 'Gửi đơn hàng',
  lang19: 'Số đơn hàng',
  lang20: 'Lợi suất sản phẩm',
  lang21: 'Số dư hiện tại',
  lang22: 'Công thức tính toán',
  lang23: 'Lợi nhuận từ đơn hàng này',
  lang24: 'Gửi đi',
  lang25: 'Nhận đơn hàng ngay',
  lang26: 'Nhận hoa hồng',
  lang27: 'Nhiệm vụ hôm nay',
  lang28: 'Đã hoàn thành hôm nay',
  lang29: 'Thủ tục',
  lang30: 'Nhấp vào nút "Bắt đầu nhiệm vụ" và làm theo hướng dẫn để hoàn thành nhiệm vụ.',
  lang31: 'Sau khi hoàn thành nhiệm vụ, bạn có thể thanh toán hoa hồng vào số dư',
  lang32: 'Đơn giá',
  lang33: 'Hoàn tiền',
  lang34: 'Đơn hàng đang xử lý',
  lang35: 'Hủy hàng đợi',
  lang36: 'Do số lượng người dùng cạnh tranh đơn hàng ở cấp độ hiện tại lớn, hệ thống đang nỗ lực phân bổ đơn hàng. Hiện đang xếp hạng thứ 11. Vui lòng kiên nhẫn chờ đợi.',
  lang37: 'Mẹo: Nâng cấp cấp độ VIP có thể giúp bạn nhận đơn hàng nhanh hơn',
  lang38: 'Nếu bạn có bất kỳ câu hỏi hoặc cần trợ giúp, vui lòng liên hệ dịch vụ khách hàng trực tuyến',
  lang39: 'Nhấp vào tôi để liên hệ dịch vụ khách hàng độc quyền của bạn⭣⭣⭣',
  lang40: 'Câu hỏi thường gặp',
  lang41: 'Làm thế nào để nạp tiền và có những phương thức thanh toán nào?',
  lang42: 'Hệ thống này mở cho nhiều quốc gia. Chúng tôi cung cấp phương thức thanh toán quốc tế và hỗ trợ tiền tệ địa phương cho các quốc gia khác nhau.',
  lang43: 'Làm thế nào tôi có thể đầu tư và kiếm thu nhập?',
  lang44: 'Bạn có thể nhận đơn hàng trong hệ thống và nhận thu nhập đặt hàng tự động ổn định và lãi suất mỗi ngày. Tất cả thu nhập có thể được rút về thẻ ngân hàng của bạn.',
  lang45: 'Làm thế nào để đăng ký làm thành viên?',
  lang46: 'Thành viên mới được đăng ký bằng lời mời. Để đảm bảo quyền lợi của thành viên đã đăng ký và ngăn chặn việc đăng ký quá mức làm giảm lợi ích, thành viên mới chỉ có thể đăng ký theo lời mời của thành viên hiện tại.',
  lang47: 'Có giới hạn về số tiền mua không?',
  lang48: 'Có, để cho phép nhiều thành viên có cơ hội nhận lợi ích, mỗi tài khoản trong hệ thống có giới hạn số tiền mua tối thiểu.',
  lang49: 'Tôi nên làm gì nếu gặp vấn đề trong quá trình vận hành?',
  lang50: 'Bạn có thể liên hệ dịch vụ khách hàng trực tuyến bất cứ lúc nào để hỗ trợ hoàn thành tất cả các hoạt động.',
  lang51: 'Liên hệ dịch vụ khách hàng',
  lang52: 'Chào mừng',
  lang53: 'Nạp tiền',
  lang54: 'Lịch sử nạp và rút tiền',
  lang55: 'Đội của tôi',
  lang56: 'Mời bạn bè',
  lang57: 'Thẻ ngân hàng rút tiền',
  lang58: 'Trung tâm bảo mật',
  lang59: 'Thiết lập',
  lang60: 'Tên ngân hàng',
  lang61: 'Trợ giúp',
  lang62: 'Của tôi',
  lang63: 'Lịch sử đơn hàng',
  lang64: 'Dịch vụ khách hàng',
  lang65: 'Costco'
};