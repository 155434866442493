import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.search";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import ProblemList from './components/problemList.vue';
export default {
  components: {
    ProblemList: ProblemList
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState({
    serviceUrl: function serviceUrl(state) {
      return state.common.serviceUrl;
    }
  })),
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 3);
  },
  methods: {
    showOnlineService: function showOnlineService() {
      //06客户的客服链接是外部加载形式，但是app无法跳转外部 所以区别处理，跳内部浏览器页面去加载
      if (window.cordova || location.search.indexOf('isapp') !== -1) {
        this.$router.push('/browser');
      } else {
        window.open(this.serviceUrl);
      }
    }
  }
};