export default {
  "lang1": "Entrar",
  "lang2": "Você também pode usar o",
  "lang3": "Login de E- mail",
  "lang4": "Ligação móvel",
  "lang5": "número de telefone",
  "lang6": "Introduza o seu número de telemóvel",
  "lang7": "Conta de e- mail",
  "lang8": "Introduza a sua conta de e-mail",
  "lang9": "Senha de autenticação",
  "lang9a": "Por favor, introduza a sua senha de acesso",
  "lang10": "Esqueceu- se da senha",
  "lang11": "registo",
  "lang12": "País de Pesquisa",
  "lang13": "Áreas comuns",
  "lang14": "Registo por E- mail",
  "lang15": "Registo de telemóvel",
  "lang16": "Definir a Senha de Login",
  "lang17": "6-20 caracteres, compostos por letras e números",
  "lang18": "Confirmar a Senha de Login",
  "lang19": "Introduza a senha novamente",
  "lang20": "Código de verificação",
  "lang21": "Introduza o código de verificação",
  "lang22": "Obter código de verificação",
  "lang23": "Código do convite (facultativo)",
  "lang23a": "Indique por favor o código do convite",
  "lang24": "li e concordei",
  "lang25": "Acordo do Utilizador",
  "lang26": "Política de Privacidade",
  "lang27": "Por favor, preencha o endereço de e-mail correcto",
  "lang28": "Por favor, preencha a senha de login de 6 a 20 dígitos",
  "lang29": "As senhas inseridas duas vezes são inconsistentes",
  "lang30": "Transferência para Android",
  "lang31": "Transferência do IOS",
  "lang32": "A moeda não pode ser retirada dentro de 24 horas após a recuperação da senha",
  "lang33": "repor a senha",
  "lang34": "Recuperação de e- mail",
  "lang35": "Recuperação do telemóvel",
  "lang36": "Carregue para iniciar a sessão",
  "lang37": "Bem-vindo ao Costco",
  "lang38": "Bem-vindos,",
  "lang39": "autenticação de identidade",
  "lang40": "Está bem.",
  "lang41": "De certificação",
  "lang42": "Em revisão",
  "lang43": "A autenticação falhou, nova autenticação",
  "lang44": "Informações de identidade",
  "lang45": "Nome completo",
  "lang46": "Por favor indique o seu nome",
  "lang47": "Número de identificação",
  "lang48": "Indique por favor o número de identificação",
  "lang49": "Carregar a imagem do ID (frente e verso)",
  "lang50": "A autenticação foi bem sucedida",
  "lang51": "Envie por favor a imagem de identificação",
  "lang52": "Centro de Segurança",
  "lang53": "Senha de autenticação",
  "lang54": "Senha do fundo",
  "lang55": "modificar",
  "lang56": "configurar",
  "lang57": "Depois de alterar a senha, é proibido retirar moedas e depositar moedas legais dentro de 24 horas",
  "lang58": "Senha antiga",
  "lang59": "Indique por favor a senha antiga",
  "lang60": "Nova Senha",
  "lang61": "Introduza uma nova senha",
  "lang62": "Confirmar a Senha",
  "lang63": "Introduza a senha de confirmação",
  "lang64": "A senha deve ser composta por 6-20 números e letras",
  "lang65": "Esqueceste-te da tua senha?",
  "lang66": "Por favor, preencha a senha antiga que consiste em 6-20 dígitos de números e letras!",
  "lang67": "Por favor, preencha uma nova senha composta por 6-20 dígitos de números e letras!",
  "lang68": "Verificação da caixa de correio",
  "lang69": "Verificação SMS",
  "lang70": "Indique por favor a senha do fundo",
  "lang71": "Confirmar a senha do fundo",
  "lang72": "Por favor, preencha a senha do fundo composta por 6-20 números e letras!",
  "lang73": "ligação",
  "lang74": "Alterar o número do telemóvel",
  "lang75": "Ligar o número de telemóvel",
  "lang76": "Definir o número do telemóvel",
  "lang77": "Por favor, insira o número de telefone celular que deseja vincular e verifique-o",
  "lang78": "Para proteger a segurança da sua conta, você precisa verificar sua conta",
  "lang79": "Enviar código de verificação para {tel} para verificação de segurança",
  "lang80": "Verificação da segurança da conta",
  "lang81": "próximo passo",
  "lang82": "Substituir a caixa de correio",
  "lang83": "Caixa de correio de ligação",
  "lang84": "Configurar a caixa de correio de ligação da conta",
  "lang85": "Por favor, insira a caixa de correio que deseja vincular e verifique-a",
  "lang86": "A minha equipa",
  "lang87": "Número de membros da equipa",
  "lang88": "Número de encaminhamentos diretos",
  "lang89": "Lista de Equipas",
  "lang90": "ID do Utilizador",
  "lang91": "Hora do convite",
  "lang92": "tempo",
  "lang93": "lucro",
  "lang94": "Detalhes do rendimento",
  "lang95": "Convidar amigos",
  "lang96": "Recomende amigos a Costco",
  "lang97": "Até 10% de comissão pode ser obtida",
  "lang98": "Convide os seus amigos a inscreverem-se",
  "lang99": "Enviar o convite",
  "lang100": "Aos amigos",
  "lang101": "Amigo completou o registo",
  "lang102": "Realizar transacções",
  "lang103": "Obter correspondente",
  "lang104": "Comissão proporcional",
  "lang105": "O meu código de convite",
  "lang106": "A ligação do meu convite",
  "lang107": "Ver o poster do convite",
  "lang108": "Regras relativas às recompensas",
  "lang109": "1. O usuário completa o registro compartilhando o código promocional ou link promocional. Para cada transação em moeda real, transação ETF e transação contratual gerada por um amigo, o compartilhador receberá uma recompensa de comissão na proporção correspondente. Após 18 de dezembro de 2021, amigos que abrirem contas por convite do compartilhador também podem desfrutar de uma recompensa de comissão de 10% separadamente.",
  "lang110": "2. Proporção de recompensa: moeda, ETF, proporção de recompensa de transação contratual: a proporção de recompensa está relacionada à posição diária/média do Token CTT do compartilhador, e as regras específicas são as seguintes:",
  "lang111": "(1) Se o Token CTT for inferior a 500, a proporção de recompensa é de 10%",
  "lang112": "(2) Mantendo 500-5000 CTT Token, a proporção de recompensa é de 20%",
  "lang113": "(3) 30% para deter mais de 5000 Tokens CTT",
  "lang114": "(4) Parceiro global Costco, entre em contato com a equipe da plataforma para maiores recompensas.",
  "lang115": "3. Forma de recompensa: A recompensa efetivamente obtida pelo compartilhador será devolvida na moeda real usada pelos amigos para liquidar as taxas de processamento de transações. Por exemplo, quando amigos usam ETH para liquidar taxas de transação, as recompensas realmente obtidas pelo compartilhador serão devolvidas pelo ETH. Quando a comissão de transação de um amigo é liquidada por dedução de CTT, as recompensas efetivamente obtidas pelo compartilhador e pelo amigo serão devolvidas por CTT.",
  "lang116": "Gravar Imagem",
  "lang117": "Gravar com sucesso a imagem",
  "lang118": "Não foi possível gravar a imagem",
  "lang119": "Ajuda e feedback",
  "lang120": "Equipa de Produtos Costco",
  "lang121": "Bem-vindo a se comunicar conosco. Seu feedback será valorizado",
  "lang122": "Detalhes do Problema",
  "lang123": "Enviar ordem de serviço",
  "lang124": "Título da Função",
  "lang125": "Indique por favor o título da função",
  "lang126": "Conteúdo da ordem de trabalho",
  "lang127": "Descreva seu problema especificamente, o que nos ajudará a ajudá-lo de forma mais eficaz!",
  "lang128": "Lista de ordens de trabalho",
  "lang129": "resposta:",
  "lang130": "Processando, por favor aguarde pacientemente",
  "lang131": "Sem ordem de serviço",
  "lang132": "A responder",
  "lang133": "Respondida",
  "lang134": "Fechado",
  "lang135": "O tamanho da imagem não pode exceder 5MB",
  "lang136": "Indique por favor o conteúdo da ordem de trabalho",
  "lang137": "O número de palavras na ordem de trabalho é 10-200",
  "lang138": "Sobre nós",
  "lang139": "Plataforma de negociação Costco",
  "lang140": "números de telefone de atendimento ao cliente",
  "lang141": "Twitter Oficial",
  "lang142": "Telegrama oficial",
  "lang143": "Email da Empresa",
  "lang144": "configurar",
  "lang145": "Configuração do Idioma",
  "lang146": "Versão n.o",
  "lang147": "Sair",
  "lang148": "Tens a certeza que vais sair da conta?",
  "lang149": "Líder mundial",
  "lang150": "Plataforma digital de negociação de ativos",
  "lang151": "Bem-vindo ao download do Costco",
  "lang152": "Conta registada",
  "lang153": "Troca de criptomoedas multifuncional e democrática. Como a primeira empresa no mercado, fornecemos benefícios econômicos para usuários ativos. Costco é um projeto geral, o que significa implementar soluções relacionadas a soluções de criptomoeda em várias camadas de negócios para facilitar o uso de nossos usuários.",
  "lang154": "A nossa visão",
  "lang155": "Estamos convencidos da ampla aplicação da tecnologia blockchain e dos benefícios da criptomoeda em um futuro próximo. Ao introduzir soluções globais, o ecossistema do mundo cripto tornou-se mais amigável para todos, não só por causa da inovação tecnológica, mas também por causa da educação.",
  "lang156": "Transferir APP",
  "lang157": "papel branco",
  "lang158": "reputação",
  "lang159": "",
  "lang160": ""
};