export default {
  lang1: 'Iniciar sesión',
  lang2: 'También puedes usar',
  lang3: 'Correo electrónico',
  lang4: 'Teléfono móvil',
  lang5: 'Teléfono',
  lang6: 'Ingrese su número de teléfono móvil',
  lang7: 'Correo electrónico',
  lang8: 'Por favor ingrese su cuenta de correo electrónico',
  lang9: 'Contraseña de inicio de sesión',
  lang9a: 'Ingrese la contraseña de inicio de sesión',
  lang10: 'Olvidé mi contraseña',
  lang11: 'Iniciar sesión',
  lang12: 'Buscar país/región',
  lang13: 'Área de uso común',
  lang14: 'Correo electrónico',
  lang15: 'Teléfono móvil',
  lang16: 'Establecer contraseña de inicio de sesión',
  lang17: '6-20 caracteres, compuestos de letras y números',
  lang18: 'Confirmar contraseña de inicio de sesión',
  lang19: 'Por favor ingrese la contraseña nuevamente',
  lang20: 'Código',
  lang21: 'Por favor ingrese el código de verificación',
  lang22: 'Enviar',
  lang23: 'Código de invitación (opcional)',
  lang23a: 'Por favor ingrese el código de invitación',
  lang24: 'He leído y acepto',
  lang25: 'Acuerdo de usuario',
  lang26: 'Política de privacidad',
  lang27: 'Ingrese la dirección de correo electrónico correcta',
  lang28: 'Ingrese la contraseña de inicio de sesión que consta de 6 a 20 dígitos y letras',
  lang29: 'Las dos contraseñas de entrada son inconsistentes',
  lang30: 'Descarga de Android',
  lang31: 'descarga de iOS',
  lang32: 'Después de recuperar la contraseña, la moneda no se puede retirar dentro de las 24 horas',
  lang33: 'Restablecer contraseña',
  lang34: 'Correo electrónico',
  lang35: 'Teléfono móvil',
  lang36: 'Haga clic para iniciar sesión',
  lang37: 'Bienvenido a Costco',
  lang38: 'Bienvenido',
  lang39: 'KYC',
  lang40: 'Autenticado',
  lang41: 'Ir a autenticación',
  lang42: 'Revisión de certificación',
  lang43: 'Autenticación fallida',
  lang44: 'Información de identidad',
  lang45: 'Nombre',
  lang46: 'Por favor ingrese su nombre',
  lang47: 'Tarjeta de identidad',
  lang48: 'Ingrese el número de identificación',
  lang49: 'Subir foto de identificación (anverso y reverso)',
  lang50: 'Autenticación exitosa',
  lang51: 'Cargue la imagen de identificación',
  lang52: 'Centro de seguridad',
  lang53: 'contraseña de inicio de sesión',
  lang54: 'Contraseña de fondos',
  lang55: 'Modificar',
  lang56: 'Configuración',
  lang57: 'Después de cambiar la contraseña, los retiros y depósitos fiduciarios están prohibidos dentro de las 24 horas',
  lang58: 'Contraseña antigua',
  lang59: 'Ingrese la contraseña anterior',
  lang60: 'Nueva contraseña',
  lang61: 'Por favor ingrese una nueva contraseña',
  lang62: 'Confirmar contraseña',
  lang63: 'Por favor ingrese la contraseña de confirmación',
  lang64: 'La contraseña debe tener entre 6 y 20 números y letras',
  lang65: '¿Olvidaste tu contraseña?',
  lang66: '¡Complete la contraseña anterior que consta de 6 a 20 dígitos y letras!',
  lang67: '¡Ingrese una nueva contraseña que consta de 6 a 20 dígitos y letras!',
  lang68: 'Correo electrónico',
  lang69: 'SMS',
  lang70: 'Ingrese la contraseña del fondo',
  lang71: 'Confirmar contraseña de fondos',
  lang72: '¡Complete la contraseña del fondo que consta de 6 a 20 dígitos y letras!',
  lang73: 'Enlace',
  lang74: 'Cambiar número de teléfono móvil',
  lang75: 'Vincular número de teléfono móvil',
  lang76: 'Establecer número de teléfono',
  lang77: 'Ingrese el número de teléfono móvil que desea vincular y verifique el número',
  lang78: 'Para proteger la seguridad de la cuenta, debe verificar su cuenta',
  lang79: 'Enviar código de verificación a {tel} para verificación de seguridad',
  lang80: 'Verificación de seguridad de la cuenta',
  lang81: 'Siguiente',
  lang82: 'Cambiar correo electrónico',
  lang83: 'Enlazar correo electrónico',
  lang84: 'Establecer correo electrónico vinculante para la cuenta',
  lang85: 'Ingrese la dirección de correo electrónico que desea vincular y verifique la dirección de correo electrónico',
  lang86: 'Mi equipo',
  lang87: 'Total de miembros del equipo',
  lang88: 'Número de invitados',
  lang89: 'Lista de equipos',
  lang90: 'ID de usuario',
  lang91: 'Hora de la invitación',
  lang92: 'tiempo',
  lang93: 'Ingresos',
  lang94: 'Detalles de ingresos',
  lang95: 'Invitar amigos',
  lang96: 'Recomendar a un amigo a Costco',
  lang97: 'Hasta un 10% de descuento en la tarifa de transacción',
  lang98: 'Invitar a amigos a registrarse juntos',
  lang99: 'Enviar invitación',
  lang100: 'Para amigos',
  lang101: 'Registro de amigos',
  lang102: 'Hacer una transacción',
  lang103: 'Obtener correspondiente',
  lang104: 'Descuento proporcional',
  lang105: 'Mi código',
  lang106: 'Mi enlace',
  lang107: 'Ver póster',
  lang108: 'Reglas de recompensas',
  lang109: '1. El usuario completa el registro compartiendo el código de promoción o el enlace de la promoción. Cada vez que un amigo genera una transacción de moneda real, una transacción de ETF y una transacción de contrato, el que comparte puede obtener una proporción correspondiente de la recompensa de la tarifa de manejo. Para diciembre 18, 2021 En el futuro, los amigos que inviten a amigos que abran una cuenta a través del compartidor también podrán disfrutar de una recompensa de comisión del 10 %.',
  lang110: '2. Tasa de recompensa: moneda, ETF, tasa de recompensa de negociación de contratos: La tasa de recompensa está relacionada con las tenencias diarias/promedio de tokens CTT del participante. Las reglas específicas son las siguientes:',
  lang111: '(1) el token CTT es inferior a 500, la tasa de recompensa es del 10 %',
  lang112: '(2) Mantenga 500-5000 Token CTT, la tasa de recompensa es 20%',
  lang113: '(3) Si tiene más de 5000 tokens CTT, la tasa de recompensa es del 30 %',
  lang114: '(4) Socios globales de Costco, comuníquese con el personal de la plataforma para obtener mayores recompensas.',
  lang115: '3. Forma de recompensa: las recompensas realmente obtenidas por el participante se devolverán en la moneda real utilizada por el amigo para liquidar la tarifa de transacción. Por ejemplo: cuando el amigo usa ETH para liquidar la tarifa de transacción, la recompensa real obtenida por el que comparte se devolverá en ETH. .Cuando la tarifa de transacción del amigo se liquida mediante deducción de CTT, las recompensas reales obtenidas por el que comparte y el amigo se devolverán en CTT.',
  lang116: 'Guardar imagen',
  lang117: 'Guardar la imagen correctamente',
  lang118: 'Error al guardar la imagen',
  lang119: 'Centro de ayuda',
  lang120: 'Equipo de productos Costco',
  lang121: 'Bienvenido a comunicarse con nosotros, todos sus comentarios serán valorados',
  lang122: 'Detalles del problema',
  lang123: 'Enviar orden de trabajo',
  lang124: 'Título de la orden de trabajo',
  lang125: 'Ingrese el título del boleto',
  lang126: 'Contenido de la orden de trabajo',
  lang127: 'Por favor, describa su problema en detalle, ¡nos ayudará a ayudarlo de manera más efectiva!',
  lang128: 'Lista de órdenes de trabajo',
  lang129: 'Respuesta:',
  lang130: 'Procesando, tenga paciencia...',
  lang131: 'Todavía no hay orden de trabajo',
  lang132: 'esperando',
  lang133: 'Respondido',
  lang134: 'cerrado',
  lang135: 'El tamaño de la imagen no puede exceder los 5 MB',
  lang136: 'Ingrese el contenido de la orden de trabajo',
  lang137: 'El número de palabras en la orden de trabajo es entre 10 y 200',
  lang138: 'Acerca de nosotros',
  lang139: 'Plataforma de negociación Costco',
  lang140: 'Teléfono de atención al cliente',
  lang141: 'Twitter oficial',
  lang142: 'Telegrama oficial',
  lang143: 'Buzón de la empresa',
  lang144: 'Configuración',
  lang145: 'Idioma',
  lang146: 'Versión',
  lang147: 'Cerrar sesión',
  lang148: '¿Está seguro de que desea cerrar la sesión?',
  lang149: 'El líder mundial',
  lang150: 'Plataforma de negociación de activos digitales',
  lang151: 'Bienvenido a descargar Costco',
  lang152: 'Iniciar sesión',
  lang153: 'Criptointercambio multifuncional y democrático. Como los primeros en el mercado, brindamos beneficios financieros a los usuarios activos. Costco es un proyecto holístico, lo que significa implementar soluciones en algunas capas comerciales con respecto a las soluciones de criptomonedas para facilitar el uso de nuestros usuarios .',
  lang154: 'Nuestra visión',
  lang155: 'Estamos seguros de la amplia aplicación de la tecnología blockchain y los beneficios de las criptomonedas en un futuro cercano. Al introducir soluciones globales no solo debido a las innovaciones tecnológicas sino también gracias a la educación, el ecosistema del mundo criptográfico se vuelve más amigable para todos',
  lang156: 'Descargar APLICACIÓN',
  lang157: 'libros blancos',
  lang158: 'Crédito'
};