var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "graborders" },
    [
      _c("nav-header", { attrs: { title: _vm.$t("addLang.lang4") } }),
      _c(
        "div",
        { staticClass: "main_content help_center" },
        [
          _c("div", { staticClass: "bg-template" }, [
            _c("img", {
              attrs: { src: require("@img/home/grabordersImg1.jpg"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "shadowBox container" }, [
            _c(
              "div",
              { staticClass: "orderBtn" },
              [
                _c(
                  "van-button",
                  {
                    attrs: { type: "primary", size: "large" },
                    on: { click: _vm.showDialog }
                  },
                  [_vm._v(_vm._s(_vm.$t("addLang.lang25")))]
                )
              ],
              1
            ),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "card-body-list" }, [
                _c("div", { staticClass: "card-body-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/home/grabordersImg2.jpg"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "card-body-title" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang26")))
                ]),
                _c("div", { staticClass: "card-body-desc" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("mathFloor")(_vm.earningsInfo.rebateDayAmount, 2)
                    ) + "$"
                  )
                ])
              ]),
              _c("div", { staticClass: "card-body-list" }, [
                _c("div", { staticClass: "card-body-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/home/grabordersImg3.jpg"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "card-body-title" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang10")))
                ]),
                _c("div", { staticClass: "card-body-desc" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("mathFloor")(_vm.exchangeCoinList.usable, 2)
                    ) + "$"
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "div",
                {
                  staticClass: "card-body-list",
                  on: {
                    click: function($event) {
                      return _vm.pushPath("/ordersrecord")
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card-body-img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@img/home/grabordersImg4.jpg"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("div", { staticClass: "card-body-title" }, [
                    _vm._v(_vm._s(_vm.$t("addLang.lang27")))
                  ]),
                  _c("div", { staticClass: "card-body-desc" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("mathFloor")(_vm.viplevInfo.purchaseLimit, 2)
                      )
                    )
                  ])
                ]
              ),
              _c("div", { staticClass: "card-body-list" }, [
                _c("div", { staticClass: "card-body-img" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/home/grabordersImg5.jpg"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "card-body-title" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang28")))
                ]),
                _c("div", { staticClass: "card-body-desc" }, [
                  _vm._v(_vm._s(_vm.earningsInfo.orderCount))
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "help" }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("addLang.lang29")) + "：")]),
            _c("p", [_vm._v("1、" + _vm._s(_vm.$t("addLang.lang30")))]),
            _c("p", [_vm._v("2、" + _vm._s(_vm.$t("addLang.lang31")))])
          ]),
          _c(
            "div",
            { staticClass: "goods" },
            _vm._l(_vm.goodsList, function(item, index) {
              return _c("div", { key: index, staticClass: "goods-item" }, [
                _c("div", { staticClass: "goods-img" }, [
                  _c("img", {
                    attrs: { src: "/api/res/preview/" + item.picture, alt: "" }
                  })
                ]),
                _c("div", { staticClass: "goods-title" }, [
                  _vm._v(_vm._s(item.productName))
                ]),
                _c("span", { staticClass: "goods-price" }, [
                  _vm._v(
                    _vm._s(_vm.$t("addLang.lang32")) +
                      "：" +
                      _vm._s(_vm._f("mathFloor")(item.amount, 0)) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("addLang.lang33")) +
                      _vm._s(
                        _vm._f("mathFloor")(
                          item.amount * _vm.viplevInfo.rebate,
                          3
                        )
                      )
                  )
                ])
              ])
            }),
            0
          ),
          _c(
            "van-dialog",
            {
              attrs: {
                "confirm-button-text": _vm.$t("addLang.lang35"),
                title: _vm.$t("addLang.lang34"),
                "show-cancel": true
              },
              on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
              model: {
                value: _vm.dialogVisible,
                callback: function($$v) {
                  _vm.dialogVisible = $$v
                },
                expression: "dialogVisible"
              }
            },
            [
              _c("div", { staticClass: "befor-order" }, [
                _c("div", { staticClass: "van-circle" }, [
                  _c("svg", { attrs: { viewBox: "0 0 1040 1040" } }, [
                    _c("path", {
                      staticClass: "van-circle__layer",
                      staticStyle: { fill: "none", "stroke-width": "40px" },
                      attrs: {
                        d:
                          "M 520 520 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000"
                      }
                    }),
                    _c("path", {
                      staticClass: "van-circle__hover",
                      style: _vm.hoverPathStyle,
                      attrs: {
                        d:
                          "M 520 520 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000"
                      }
                    })
                  ]),
                  _c("div", { staticClass: "van-circle__text" }, [
                    _vm._v(_vm._s(_vm.progress) + "%")
                  ])
                ]),
                _c("div", { staticClass: "befor-order-info" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang36")))]),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v(_vm._s(_vm.$t("addLang.lang37")))
                  ])
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }