var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("div", { staticClass: "main_content quotation_details" }, [
        _c("img", { attrs: { src: require("@img/home/xlogo.png"), alt: "" } }),
        _c("div", { ref: "homeBox" }, [
          _c("div", { staticClass: "sub_banner banner1" }, [
            _c("img", {
              attrs: { src: require("@img/home/banner1.jpg").default, alt: "" }
            })
          ]),
          _c("div", { staticClass: "sub_banner banner2" }, [
            _c("img", {
              attrs: { src: require("@img/home/banner2.jpg").default, alt: "" }
            })
          ]),
          _c("div", { staticClass: "sub_banner banner3" }, [
            _c("img", {
              attrs: { src: require("@img/home/banner3.jpg").default, alt: "" }
            })
          ]),
          _vm._m(0),
          _c(
            "ol",
            { staticClass: "coin_box" },
            _vm._l(_vm.goodsList, function(item, index) {
              return _c("li", { key: index, staticClass: "slide" }, [
                _c("div", { staticClass: "shop-box" }, [
                  _c("div", { staticClass: "van-image" }, [
                    _c("img", { attrs: { src: item.imgUrl.default, alt: "" } })
                  ]),
                  _c("span", [_vm._v(_vm._s(item.tip))]),
                  _c("p", [_vm._v(_vm._s(item.Price))])
                ])
              ])
            }),
            0
          ),
          _c("div", { staticClass: "nav-list" }, [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.pushPath("/coinWithdraw")
                  }
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang1")))])]
            ),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.pushPath("/balanceRecord")
                  }
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang2")))])]
            ),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.pushPath("/ordersrecord")
                  }
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang3")))])]
            ),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.pushPath("/graborders")
                  }
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang4")))])]
            )
          ]),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("h6", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang5")) + "：")
              ]),
              _vm._l(_vm.viplevList, function(item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "vip-warp",
                      class: "vip-" + "" + item.levlabel,
                      style: _vm.getBackgroundStyle(item.levlabel)
                    },
                    [
                      _c("div", { staticClass: "text-warp" }, [
                        _c("div", { staticClass: "vip-info" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("addLang.lang6")) +
                                ": " +
                                _vm._s(
                                  _vm._f("mathFloor")(item.rechargeAmount, 0)
                                )
                            )
                          ]),
                          item.levlabel === "6"
                            ? _c("p", [
                                _vm._v(_vm._s(_vm.$t("addLang.lang7")) + ": 1%")
                              ])
                            : _vm._e(),
                          item.levlabel === "5"
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("addLang.lang7")) + ": 0.9%"
                                )
                              ])
                            : _vm._e(),
                          item.levlabel === "4"
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("addLang.lang7")) + ": 0.8%"
                                )
                              ])
                            : _vm._e(),
                          item.levlabel === "3"
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("addLang.lang7")) + ":0.7%"
                                )
                              ])
                            : _vm._e(),
                          item.levlabel === "2"
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("addLang.lang7")) + ": 0.6%"
                                )
                              ])
                            : _vm._e(),
                          item.levlabel === "1"
                            ? _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("addLang.lang7")) + ": 0.5%"
                                )
                              ])
                            : _vm._e(),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("addLang.lang8")) +
                                "：" +
                                _vm._s(item.purchaseLimit)
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "assets-info",
                          on: {
                            click: function($event) {
                              return _vm.pushPath("/graborders")
                            }
                          }
                        },
                        [
                          _c("p", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("addLang.lang9")))
                            ]),
                            _vm._v(">> ")
                          ])
                        ]
                      )
                    ]
                  )
                ])
              })
            ],
            2
          ),
          _c("div", { staticClass: "sub_banner banner-last" }, [
            _c("img", {
              attrs: {
                src: require("@img/home/banner_last.jpg").default,
                alt: ""
              }
            })
          ]),
          _vm._m(1),
          _vm._m(2)
        ])
      ]),
      _c(
        "van-popup",
        {
          staticClass: "right_popup",
          attrs: { "close-on-popstate": "", "overlay-class": "block_bg" },
          model: {
            value: _vm.noticePopup,
            callback: function($$v) {
              _vm.noticePopup = $$v
            },
            expression: "noticePopup"
          }
        },
        [
          _c("nav-header", { attrs: { title: _vm.$t("homeLang.lang30") } }),
          _c("div", { staticClass: "main_content notice_details" }, [
            _c("h3", [_vm._v(_vm._s(_vm.noticeData.title))]),
            _c("p", [_vm._v(_vm._s(_vm.noticeData.wdate))]),
            _c("pre", { domProps: { innerHTML: _vm._s(_vm.noticeData.reply) } })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("h1", [_vm._v("New Arrivals")]),
      _c("p", [_vm._v("All the latest for the new season and beyond.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "floter" }, [
      _c("div", { staticClass: "image-with-text__content--inner" }, [
        _c("p", { staticClass: "subheading" }, [_vm._v("NOW AVAILABLE")]),
        _c("div", { staticClass: "text-size-regular" }, [
          _vm._v(
            " Get dressed for wedding season, pack for your next getaway and update your closet without the commitment. With our new Borrow program, you can try the latest trends and stick to your budget, no subscription required. "
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "floter2" }, [
      _c("p", [
        _vm._v(
          " Không ngừng sáng tạo và tỉ mỉ từ công đoạn nhận đơn đến các khoản thu nhập,và lợi nhuận, nhằm mang đến cho Quý Khách Hàng những trải nghiệm mua sắm đặc biệt nhất: sản phẩm chất lượng – dịch vụ hoàn hảo. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }