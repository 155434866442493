export default {
  lang1: '로그인',
  lang2: '다음을 사용할 수도 있습니다.',
  lang3: '이메일 로그인',
  lang4: '모바일 로그인',
  lang5: '휴대전화 번호',
  lang6: '휴대전화 번호를 입력하세요',
  lang7: '이메일 계정',
  lang8: '이메일 계정을 입력하세요',
  lang9: '로그인 비밀번호',
  lang9a: '로그인 비밀번호를 입력하세요',
  lang10: '비밀번호를 잊어버렸습니다',
  lang11: '등록',
  lang12: '국가/지역 검색',
  lang13: '일반적으로 사용되는 영역',
  lang14: '이메일 등록',
  lang15: '휴대전화 등록',
  lang16: '로그인 비밀번호 설정',
  lang17: '문자와 숫자로 구성된 6-20자',
  lang18: '로그인 비밀번호 확인',
  lang19: '비밀번호를 다시 입력하세요.',
  lang20: '인증 코드',
  lang21: '인증 코드를 입력하세요',
  lang22: '보내기',
  lang23: '초대 코드(선택 사항)',
  lang23a: '초대 코드를 입력하세요',
  lang24: '읽고 동의합니다',
  lang25: '사용자 계약',
  lang26: '개인정보 보호정책',
  lang27: '올바른 이메일 주소를 입력하세요',
  lang28: '6-20자리 숫자와 문자로 구성된 로그인 비밀번호를 입력하세요',
  lang29: '두 개의 입력 암호가 일치하지 않습니다',
  lang30: '안드로이드 다운로드',
  lang31: 'iOS 다운로드',
  lang32: '비밀번호를 검색한 후 24시간 이내에 통화를 인출할 수 없습니다.',
  lang33: '비밀번호 재설정',
  lang34: '사서함 검색',
  lang35: '전화 검색',
  lang36: '로그인하려면 클릭하세요.',
  lang37: 'Costco에 오신 것을 환영합니다',
  lang38: '환영합니다',
  lang39: '인증',
  lang40: '인증됨',
  lang41: '인증으로 이동',
  lang42: '인증 검토',
  lang43: '인증 실패, 재인증',
  lang44: '신원 정보',
  lang45: '이름',
  lang46: '이름을 입력하세요',
  lang47: '문서 번호',
  lang48: 'ID 번호를 입력하세요',
  lang49: '신분증 사진 업로드(앞,뒤)',
  lang50: '인증 성공',
  lang51: '신분증 사진을 올려주세요',
  lang52: '보안 센터',
  lang53: '로그인 비밀번호',
  lang54: '펀딩 비밀번호',
  lang55: '수정',
  lang56: '설정',
  lang57: '비밀번호 변경 후 24시간 이내 출금 및 법정화폐 입출금 금지',
  lang58: '이전 암호',
  lang59: '이전 비밀번호를 입력하세요',
  lang60: '새 비밀번호',
  lang61: '새 비밀번호를 입력하세요',
  lang62: '비밀번호 확인',
  lang63: '확인 암호를 입력하십시오',
  lang64: '비밀번호는 6-20자의 숫자와 문자여야 합니다',
  lang65: '비밀번호를 잊어버리셨습니까?',
  lang66: '6~20자리 숫자와 문자로 구성된 기존 비밀번호를 입력하세요!',
  lang67: '6-20자리 숫자와 문자로 구성된 새 비밀번호를 입력하세요!',
  lang68: '이메일 확인',
  lang69: 'SMS 인증',
  lang70: '펀드 비밀번호를 입력하세요',
  lang71: '펀드 비밀번호 확인',
  lang72: '6~20자리 숫자와 문자로 구성된 펀드 비밀번호를 입력하세요!',
  lang73: '바인딩',
  lang74: '휴대전화 번호 변경',
  lang75: '휴대전화 번호 묶기',
  lang76: '전화번호 설정',
  lang77: '바인드할 휴대폰 번호를 입력하고 번호를 확인하세요.',
  lang78: '계정 보안을 위해 계정을 인증해야 합니다.',
  lang79: '보안 확인을 위해 {tel}에 인증 코드를 보내주세요',
  lang80: '계정 보안 확인',
  lang81: '다음',
  lang82: '사서함 변경',
  lang83: '사서함 바인딩',
  lang84: '계정 바인딩 사서함 설정',
  lang85: '바인드할 이메일 주소를 입력하고 이메일 주소를 확인하십시오',
  lang86: '내 팀',
  lang87: '총 팀원 수',
  lang88: '초대 대상자 수',
  lang89: '팀 목록',
  lang90: '사용자 ID',
  lang91: '초대 시간',
  lang92: '시간',
  lang93: '수익',
  lang94: '수익 세부정보',
  lang95: '친구 초대',
  lang96: 'Costco에 친구 추천',
  lang97: '거래 수수료의 최대 10% 리베이트',
  lang98: '함께 등록할 친구 초대',
  lang99: '초대장 보내기',
  lang100: '친구를 위해',
  lang101: '친구 등록 완료',
  lang102: '거래하기',
  lang103: '대응하기',
  lang104: '비례 환급',
  lang105: '내 초대 코드',
  lang106: '내 초대 링크',
  lang107: '초대장 포스터 보기',
  lang108: '보상 규칙',
  lang109: '1. 사용자는 프로모션 코드 또는 프로모션 링크를 공유하여 등록을 완료합니다. 친구가 실제 통화 거래, ETF 거래 및 계약 거래를 생성할 때마다 공유자는 해당 비율의 수수료 보상을 받을 수 있습니다. 12월 2021년 1월 18일 앞으로 공유기를 통해 계정을 개설한 친구를 초대한 친구도 10% 커미션 보상을 단독으로 누릴 수 있습니다.',
  lang110: '2. 보상 비율: 통화, ETF, 계약 거래 보상 비율: 보상 비율은 공유자의 일/일 평균 CTT Token 보유량과 관련이 있습니다. 구체적인 규칙은 다음과 같습니다.',
  lang111: '(1) CTT Token이 500 미만, 보상 비율이 10%',
  lang112: '(2) 500-5000CTT 토큰 보유, 보상 비율은 20%',
  lang113: '(3) 5,000CTT 이상의 토큰을 보유하고 있는 경우 보상 비율은 30%입니다.',
  lang114: '(4) Costco 글로벌 파트너, 더 높은 보상을 받으려면 플랫폼 직원에게 문의하십시오.',
  lang115: '3. 보상 형식: 공유자가 실제로 얻은 보상은 거래 수수료를 정산하기 위해 친구가 사용한 실제 통화로 반환됩니다. 예: 친구가 거래 수수료를 정산하기 위해 ETH를 사용하는 경우 실제 얻은 보상 공유자에 의해 ETH로 반환됩니다. .친구의 거래 수수료가 CTT 차감으로 정산되면 공유자와 친구가 얻은 실제 보상은 CTT로 반환됩니다.',
  lang116: '사진 저장',
  lang117: '사진 저장 성공',
  lang118: '이미지를 저장하지 못했습니다',
  lang119: '도움말 및 피드백',
  lang120: 'Costco 제품 팀',
  lang121: '의사소통에 오신 것을 환영합니다. 귀하의 피드백은 모두 소중합니다.',
  lang122: '문제 세부정보',
  lang123: '작업 주문 제출',
  lang124: '작업 주문 제목',
  lang125: '티켓 제목을 입력하세요',
  lang126: '작업 주문 내용',
  lang127: '문제를 자세히 설명하세요. 더 효과적으로 도와드리겠습니다!',
  lang128: '작업 주문 목록',
  lang129: '답장:',
  lang130: '처리 중입니다. 잠시만 기다려 주십시오...',
  lang131: '아직 작업 주문이 없습니다',
  lang132: '응답하다',
  lang133: '응답했습니다',
  lang134: '닫힌',
  lang135: '이미지 크기는 5MB를 초과할 수 없습니다.',
  lang136: '작업 지시의 내용을 입력하십시오',
  lang137: '작업 순서의 단어 수는 10-200입니다.',
  lang138: '회사 소개',
  lang139: 'Costco 거래 플랫폼',
  lang140: '고객 서비스 전화',
  lang141: '공식 트위터',
  lang142: '공식 텔레그램',
  lang143: '회사 사서함',
  lang144: '설정',
  lang145: '언어 설정',
  lang146: '버전 번호',
  lang147: '로그아웃',
  lang148: '로그아웃하시겠습니까?',
  lang149: '세계 최고의',
  lang150: '디지털 자산 거래 플랫폼',
  lang151: 'Costco 다운로드를 환영합니다',
  lang152: '계정 등록',
  lang153: '다기능적이고 민주적인 암호화폐 거래소입니다. 최초의 시장으로서 활성 사용자에게 재정적 혜택을 제공합니다. Costco는 전체론적 프로젝트입니다. 즉, 사용자가 더 쉽게 사용할 수 있도록 암호화폐 솔루션과 관련된 몇 가지 비즈니스 계층에 솔루션을 구현하는 것을 의미합니다. .',
  lang154: '우리의 비전',
  lang155: '우리는 가까운 장래에 블록체인 기술의 광범위한 적용과 암호화폐의 이점을 확신합니다. 기술 혁신뿐만 아니라 교육 덕분에 글로벌 솔루션을 도입함으로써 암호화폐 세계 생태계는 모두에게 더 친숙해집니다.',
  lang156: '앱 다운로드',
  lang157: '하얀 종이',
  lang158: '신용 거래',
  lang159: '',
  lang160: ''
};