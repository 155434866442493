import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.search";
import _defineProperty from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";

var _components$data$comp;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import areaCoinList from './components/areaCoinList.vue';
import { mapState } from 'vuex';
import { tradeOverview, tradeSymbols } from '@api/exchange';
export default (_components$data$comp = {
  components: {
    areaCoinList: areaCoinList
  },
  data: function data() {
    return {
      // bannerList:[
      //   {banner:require('@img/common/home_banner.png')},
      //   {banner:require('@img/common/home_banner.png')},
      //   {banner:require('@img/common/home_banner.png')},
      // ],
      // noticeList:[
      //   {title:'关于交易所相关问题,欢迎咨询来电',wdate:'2020-10-23 12:12:13'},
      //   {title:'关于交易所相关问题,欢迎咨询来电',wdate:'2020-10-23 12:12:13'},
      //   {title:'关于交易所相关问题,欢迎咨询来电',wdate:'2020-10-23 12:12:13'},
      // ],
      noticePopup: false,
      noticeData: {},
      overviewList: [],
      areaType: 'amount',
      riseList: [],
      fallList: [],
      volList: [],
      coinList: [],
      isHidePanel: false,
      goodsList: [{
        id: 1,
        tip: "Introduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin1.jpg')
      }, {
        id: 2,
        tip: "1998 mini square Classic Flap shoulder bagcombined with the leather and chain-link shoulder strap and the iconic interlocking CC turn-lock, results in this mini square Classic Flap shoulder bag.",
        Price: "$ 10903",
        imgUrl: require('@img/home/coin2.jpg')
      }, {
        id: 3,
        tip: "Split Frame logo shoulder bagThere are a lot of theories about where the interlocking CC logo from Chanel came from, but one thing is certain: it represents the brand exquisitely. Stitched on the front of this patent shoulder bag, the symbol embellishes a limited edition design with a Split Frame style.",
        Price: "$ 14736",
        imgUrl: require('@img/home/coin3.jpg')
      }, {
        id: 4,
        tip: "1995 tweed Classic Flap handbagIntroduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin4.jpg')
      }, {
        id: 5,
        tip: "2000s tweed-trim jacketThe model is also styled with: Alexander McQueen skull motif crossbody bag, CHANEL Pre-Owned 2009 gathered silk blouse, Kwaidan Editions 65mm leather mules",
        Price: "$ 11705",
        imgUrl: require('@img/home/coin5.jpg')
      }, {
        id: 6,
        tip: "Introduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin6.jpg')
      }, {
        id: 7,
        tip: "Introduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin7.jpg')
      }, {
        id: 8,
        tip: "Introduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin8.jpg')
      }, {
        id: 9,
        tip: "Introduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin9.jpg')
      }, {
        id: 10,
        tip: "Introduced by Gabrielle Chanel to guarantee the quality and durability of leather bags, diamond quilting has become one of Chanel's signatures. On this Classic Flap handbag, the traditional black leather is replaced with pure wool tweed, showcasing the model’s iconic interlocking CC turn-lock.",
        Price: "$ 21174",
        imgUrl: require('@img/home/coin10.jpg')
      }],
      viplevParams: {
        page: 1,
        limit: 20
      },
      vipBackgrounds: {
        '0': require('@img/home/v0.png'),
        '1': require('@img/home/v1.png'),
        '2': require('@img/home/v2.png'),
        '3': require('@img/home/v3.png'),
        '4': require('@img/home/v4.png'),
        '5': require('@img/home/v5.png'),
        '6': require('@img/home/v6.png')
      }
    };
  },
  computed: _objectSpread({}, mapState({
    bannerList: function bannerList(state) {
      return state.common.bannerList;
    },
    noticeList: function noticeList(state) {
      return state.common.noticeList;
    },
    marketSocketData: function marketSocketData(state) {
      return state.trade.marketSocketData;
    },
    rateData: function rateData(state) {
      return state.wallet.rateData;
    },
    serviceUrl: function serviceUrl(state) {
      return state.common.serviceUrl;
    },
    helpList: function helpList(state) {
      return state.common.helpList;
    },
    viplevList: function viplevList(state) {
      return state.common.viplevList;
    }
  })),
  watch: {
    // 监听socket 数据  实时更新
    marketSocketData: function marketSocketData() {
      this.initMarket();
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.isHidePanel = false;
  },
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 0);
    this.$store.dispatch('common/getBanner');
    this.$store.dispatch('common/getNotice');
    this.$store.dispatch('common/getHelpList');
    this.$store.dispatch('common/getViplevList', this.viplevParams);
    this.initMarket();
  }
}, _defineProperty(_components$data$comp, "watch", {
  helpList: function helpList(newVal) {
    if (newVal && newVal.length > 0) {
      this.initHelp();
    }
  }
}), _defineProperty(_components$data$comp, "methods", {
  // 首页刷新弹框
  initHelp: function initHelp() {// const { title, reply } = this.helpList[0] || {}
    // Dialog.alert({
    //   title: title,
    //   message: reply,
    //   confirmButtonText: this.$t('commonLang.lang9')
    // }).then(() => {
    // });
  },
  getBackgroundStyle: function getBackgroundStyle(levlabel) {
    var backgroundImageUrl = this.vipBackgrounds[levlabel] && this.vipBackgrounds[levlabel].default || '';
    ; // 如果找不到对应的 levlabel，则使用空字符串作为备选

    return {
      background: "url(".concat(backgroundImageUrl, ") 0% 0% / 100% 100% no-repeat")
    };
  },
  //app读取banner图需要拼接服务器地址
  getBannerImgUrl: function getBannerImgUrl(url) {
    return "/api/res/preview/".concat(url);
  },
  changeAreaType: function changeAreaType(index) {
    this.areaType = ['amount', 'rise', 'fall'][index];
  },
  changeType: function changeType(item, index) {
    this.areaType = item;
    this.$refs.areaSwipe.swipeTo(index);
    this.$refs.areaSwipe.resize();
  },
  showNoticePopup: function showNoticePopup(data) {
    this.noticeData = data;
    this.pushState('?noticePopup');
    this.noticePopup = true;
  },
  initMarket: function initMarket() {
    if (this.marketSocketData && this.marketSocketData.symbols) {
      // 更新主要币种数据
      this.overviewList = this.marketSocketData.overview; // 更新行情

      if (this.marketSocketData.symbols) {
        this.processData(this.marketSocketData.symbols);
      }
    } else {
      this.getOverview();
      this.getExchangeSymbols();
    }
  },
  getOverview: function getOverview() {
    var _this = this;

    tradeOverview().then(function (res) {
      _this.overviewList = res.data;
    });
  },
  getExchangeSymbols: function getExchangeSymbols() {
    var _this2 = this;

    tradeSymbols().then(function (res) {
      _this2.processData(res.data);
    });
  },
  // 加工行情数据
  processData: function processData(item) {
    // this.coinList = item;
    this.riseList = [];
    this.fallList = [];
    this.volList = [];
    this.coinList = [];

    if (!item) {
      return;
    }

    this.riseList = item.filter(function (data) {
      return data.rise >= 0;
    });
    this.fallList = item.filter(function (data) {
      return data.rise < 0;
    });
    this.riseList.sort(this.$utils.compareRise('rise'));
    this.fallList.sort(this.$utils.compareRise('rise', 'up'));
    this.volList = _toConsumableArray(item);
    this.volList.sort(this.$utils.compareRise('volume'));

    if (this.areaType == 'rise') {
      this.coinList = _toConsumableArray(this.riseList);
    } else if (this.areaType == 'fall') {
      this.coinList = _toConsumableArray(this.fallList);
    } else if (this.areaType == 'amount') {
      this.coinList = _toConsumableArray(this.volList);
    }
  },
  goDetails: function goDetails(data) {
    sessionStorage.setItem('marketDetails', JSON.stringify(data));
    this.pushPath('/marketDetails/trad');
  },
  showOnlineService: function showOnlineService() {
    //06客户的客服链接是外部加载形式，但是app无法跳转外部 所以区别处理，跳内部浏览器页面去加载
    if (window.cordova || location.search.indexOf('isapp') !== -1) {
      this.$router.push('/browser');
    } else {
      window.open(this.serviceUrl);
    }
  }
}), _components$data$comp);