import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.search";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      viplevParams: {
        page: 1,
        limit: 20
      },
      vipBackgrounds: {
        '0': require('@img/home/v0.png'),
        '1': require('@img/home/v1.png'),
        '2': require('@img/home/v2.png'),
        '3': require('@img/home/v3.png'),
        '4': require('@img/home/v4.png'),
        '5': require('@img/home/v5.png'),
        '6': require('@img/home/v6.png')
      }
    };
  },
  computed: _objectSpread({}, mapState({
    viplevInfo: function viplevInfo(state) {
      return state.common.viplevInfo;
    },
    userInfo: function userInfo(state) {
      return state.user.userInfo;
    },
    exchangeCoinList: function exchangeCoinList(state) {
      return state.wallet.exchangeCoinList[0] || {};
    },
    serviceUrl: function serviceUrl(state) {
      return state.common.serviceUrl;
    }
  })),
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 4);
    this.$store.dispatch('common/getViplevList', this.viplevParams);
    this.$store.dispatch('user/getUserInfo');
    this.$store.dispatch('wallet/getExchangeAssets');
  },
  methods: {
    getBackgroundStyle: function getBackgroundStyle() {
      var levlabel = '0';

      if (this.viplevInfo !== 'undefined') {
        levlabel = this.viplevInfo.levlabel || '0';
      }

      var backgroundImageUrl = this.vipBackgrounds[levlabel] && this.vipBackgrounds[levlabel].default || '';
      ; // 如果找不到对应的 levlabel，则使用空字符串作为备选

      return {
        background: "url(".concat(backgroundImageUrl, ") center 3.5px / 100% 100% no-repeat")
      };
    },
    showOnlineService: function showOnlineService() {
      //06客户的客服链接是外部加载形式，但是app无法跳转外部 所以区别处理，跳内部浏览器页面去加载
      if (window.cordova || location.search.indexOf('isapp') !== -1) {
        console.log(111);
        this.$router.push('/browser');
      } else {
        console.log(1122);
        window.open(this.serviceUrl);
      }
    }
  }
};