export default {
  lang1: 'Đăng nhập',
  lang2: 'Bạn cũng có thể sử dụng',
  lang3: 'Email',
  lang4: 'Điện thoại di động',
  lang5: 'Điện thoại',
  lang6: 'Vui lòng nhập số điện thoại di động của bạn',
  lang7: 'Email',
  lang8: 'Vui lòng nhập tài khoản email của bạn',
  lang9: 'Mật khẩu đăng nhập',
  lang9a: 'Vui lòng nhập mật khẩu đăng nhập',
  lang10: 'Quên mật khẩu',
  lang11: 'Đăng ký ngay',
  lang111: '(1) CTT Token ít hơn 500, tỷ lệ thưởng là 10%',
  lang112: '(2) Nắm giữ 500-5000 CTT Token, tỷ lệ thưởng là 20%',
  lang113: '(3) Nếu nắm giữ hơn 5.000 CTT Token, tỷ lệ thưởng là 30%',
  lang114: '(4) Đối tác toàn cầu Costco, vui lòng liên hệ nhân viên nền tảng để nhận phần thưởng cao hơn.',
  lang115: '3. Hình thức thưởng: Phần thưởng thực tế mà người chia sẻ nhận được sẽ được hoàn trả bằng đồng tiền thực tế mà bạn bè sử dụng để thanh toán phí giao dịch. Ví dụ: khi bạn bè sử dụng ETH để thanh toán phí giao dịch, phần thưởng thực tế mà người chia sẻ nhận được sẽ được hoàn trả bằng ETH. Khi phí giao dịch của bạn bè được thanh toán bằng khấu trừ CTT, phần thưởng thực tế mà người chia sẻ và bạn bè nhận được sẽ được hoàn trả bằng CTT.',
  lang116: 'Lưu hình ảnh',
  lang117: 'Lưu hình ảnh thành công',
  lang118: 'Lưu hình ảnh thất bại',
  lang119: 'Trung tâm trợ giúp',
  lang120: 'Đội ngũ sản phẩm Costco',
  lang121: 'Chào mừng trao đổi với chúng tôi, mọi phản hồi của bạn sẽ được đánh giá cao',
  lang122: 'Chi tiết vấn đề',
  lang123: 'Gửi phiếu hỗ trợ',
  lang124: 'Tiêu đề phiếu hỗ trợ',
  lang125: 'Vui lòng nhập tiêu đề của phiếu hỗ trợ',
  lang126: 'Nội dung phiếu hỗ trợ',
  lang127: 'Vui lòng mô tả chi tiết vấn đề của bạn, điều này sẽ giúp chúng tôi hỗ trợ bạn hiệu quả hơn!',
  lang128: 'Danh sách phiếu hỗ trợ',
  lang129: 'Trả lời:',
  lang130: 'Đang xử lý, vui lòng kiên nhẫn...',
  lang131: 'Chưa có phiếu hỗ trợ',
  lang132: 'Đang chờ',
  lang133: 'Đã phản hồi',
  lang134: 'Đã đóng',
  lang135: 'Kích thước hình ảnh không được vượt quá 5MB',
  lang136: 'Vui lòng nhập nội dung phiếu hỗ trợ',
  lang137: 'Số từ trong phiếu hỗ trợ phải từ 10-200',
  lang138: 'Về chúng tôi',
  lang139: 'Nền tảng giao dịch Costco',
  lang140: 'Điện thoại dịch vụ khách hàng',
  lang141: 'Twitter chính thức',
  lang142: 'Telegram chính thức',
  lang143: 'Hộp thư công ty',
  lang144: 'Cài đặt',
  lang145: 'Ngôn ngữ',
  lang146: 'Phiên bản',
  lang147: 'Đăng xuất',
  lang148: 'Bạn có chắc chắn muốn đăng xuất không?',
  lang149: 'Hàng đầu thế giới',
  lang150: 'Nền tảng giao dịch tài sản kỹ thuật số',
  lang151: 'Chào mừng tải xuống Costco',
  lang152: 'Đăng ký',
  lang153: 'Sàn giao dịch tiền điện tử đa chức năng và dân chủ. Là người đầu tiên trên thị trường, chúng tôi mang lại lợi ích tài chính cho người dùng tích cực. Costco là một dự án toàn diện, có nghĩa là triển khai giải pháp trên một số lớp kinh doanh liên quan đến giải pháp tiền điện tử để làm cho việc sử dụng dễ dàng hơn cho người dùng của chúng tôi.',
  lang154: 'Tầm nhìn của chúng tôi',
  lang155: 'Chúng tôi tin tưởng vào việc ứng dụng rộng rãi công nghệ blockchain và lợi ích của tiền điện tử trong tương lai gần. Bằng cách giới thiệu các giải pháp toàn cầu không chỉ nhờ vào đổi mới công nghệ mà còn nhờ vào giáo dục, hệ sinh thái thế giới tiền điện tử trở nên thân thiện hơn với mọi người.',
  lang156: 'Tải xuống APP',
  lang157: 'Sách trắng',
  lang158: 'Tín dụng'
};