import "core-js/modules/es.object.to-string";
export default [{
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/views/user/login');
  },
  meta: {
    level: 10
  }
}, {
  path: '/register/:code',
  name: 'register',
  component: function component() {
    return import('@/views/user/register');
  },
  meta: {
    level: 11
  }
}, {
  path: '/resetPass',
  name: 'resetPass',
  component: function component() {
    return import('@/views/user/resetPass');
  },
  meta: {
    level: 11
  }
}, {
  path: '/downloadApp',
  name: 'downloadApp',
  component: function component() {
    return import('@/views/user/downloadApp');
  },
  meta: {
    level: 12
  }
}, // {
//   path: '/personal',
//   name: 'personal',
//   component: () =>
//     import ('@/views/my/personal'),
//   meta: { level: 2 ,requiresAuth: true}
// },
// {
//   path: '/my',
//   name: 'my',
//   component: () =>
//     import ('@/views/my/mainIndex'),
//   meta: { level: 2 }
// },
{
  path: '/inviteFriends',
  name: 'inviteFriends',
  component: function component() {
    return import('@/views/my/inviteFriends');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/myTeam',
  name: 'myTeam',
  component: function component() {
    return import('@/views/my/myTeam');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/securityCenter',
  name: 'securityCenter',
  component: function component() {
    return import('@/views/my/securityCenter');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/idAuth',
  name: 'idAuth',
  component: function component() {
    return import('@/views/my/idAuth');
  },
  meta: {
    level: 4,
    requiresAuth: true
  }
}, {
  path: '/modifyLoginPass',
  name: 'modifyLoginPass',
  component: function component() {
    return import('@/views/my/modifyLoginPass');
  },
  meta: {
    level: 4,
    requiresAuth: true
  }
}, {
  path: '/modifyPayPass',
  name: 'modifyPayPass',
  component: function component() {
    return import('@/views/my/modifyPayPass');
  },
  meta: {
    level: 4,
    requiresAuth: true
  }
}, {
  path: '/bindPhone',
  name: 'bindPhone',
  component: function component() {
    return import('@/views/my/bindPhone');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/bindEmail',
  name: 'bindEmail',
  component: function component() {
    return import('@/views/my/bindEmail');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/helpCenter',
  name: 'helpCenter',
  component: function component() {
    return import('@/views/my/helpCenter');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/aboutUs',
  name: 'aboutUs',
  component: function component() {
    return import('@/views/my/aboutUs');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/certificate',
  name: 'certificate',
  component: function component() {
    return import('@/views/my/certificate');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/setting',
  name: 'setting',
  component: function component() {
    return import('@/views/my/setting');
  },
  meta: {
    level: 3
  }
}];